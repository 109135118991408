import React, { useState } from "react";
import { Button, Col, Flex, Row } from "antd";
import { ClearOutlined, DownloadOutlined } from "@ant-design/icons";
import InitBotForm from "./InitBotForm.tsx";
import { ErrorBoundary } from "react-error-boundary";

const InitBot = (Props) => {
  const [isSessionAvailbel, setIsSessionAvailbel] = useState<any | null>(true);

  const onResetBtnClick = (event) => {
    setIsSessionAvailbel(null);
    setTimeout(() => {
      setIsSessionAvailbel(true);
    });
  };

  const onExportClick = (event) => {
    // setInputList(initialFormState);
  };

  return (
    <div
      style={{
        background: "#fff",
        height: "100%",
        textAlign: "left",
        padding: "10px",
        border: "1px solid #e8e8e8",
      }}
    >
      <Row gutter={[16, 8]} style={{ margin: 0 }}>
        <Col span={24} className="BotActionBar">
          <Flex gap="small" vertical>
            <Flex wrap="wrap" gap="small" justify="space-between" style={{marginBottom: "9px"}}>
              <div>Seesion {Props.id}</div>
              <Flex justify="space-between" gap="small">
                <Button size="small" onClick={onResetBtnClick} icon={<ClearOutlined />}>
                  Reset
                </Button>
                <Button size="small" onClick={onExportClick} icon={<DownloadOutlined />}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Col>
      </Row>
      <>
        {isSessionAvailbel != null && (
          <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
            <InitBotForm />
          </ErrorBoundary>
        )} 
      </>
    </div>
  );
};

export default InitBot;
