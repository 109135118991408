import { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const DataFrame = (props) => {

    const { TableData } = props;
    const data = TableData;

    // get table column
    const columnKesys = TableData != null ? Object.keys(TableData[0]) : [];
    let tableColumn = [];
    if (columnKesys.length > 0) {
        columnKesys.forEach(element => {
            tableColumn.push(
                {
                    header: element,
                    accessorKey: element,
                    enableGrouping: true,
                }
            )
        });
    }
    console.log(tableColumn);


    // console.log(columnKesys.map((item) => return { "header": item, "accessorKey": item, enableGrouping: true, }));
    const averageSalary = useMemo(
        () => data.reduce((acc, curr) => acc + curr.salary, 0) / data.length,
        [],
    );

    const maxAge = useMemo(
        () => data.reduce((acc, curr) => Math.max(acc, curr.age), 0),
        [],
    );

    const columnType = [
        {
            header: 'First Name',
            accessorKey: 'firstName',
            enableGrouping: true, //do not let this column be grouped
        },
        {
            header: 'Last Name',
            accessorKey: 'lastName',
            enableGrouping: true,
        },
        {
            header: 'Age',
            accessorKey: 'age',
            enableGrouping: true,
        },
        {
            header: 'Gender',
            accessorKey: 'gender',
            enableGrouping: true,
        },
        {
            header: 'State',
            accessorKey: 'state',
            enableGrouping: true,
        },
        {
            header: 'Salary',
            accessorKey: 'salary',
            enableGrouping: true,
        },
    ];

    const columns = useMemo(
        () => tableColumn,
    );

    const csvConfig = mkConfig({

        fieldSeparator: ',',

        decimalSeparator: '.',

        useKeysAsHeaders: true,

    });

    const handleExportRows = (rows) => {

        const rowData = rows.map((row) => row.original);

        const csv = generateCsv(csvConfig)(rowData);

        download(csvConfig)(csv);

    };

    const handleExportData = () => {

        const csv = generateCsv(csvConfig)(data);

        download(csvConfig)(csv);

    };


    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnResizing: true,
        enableGrouping: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnOrdering: true,
        initialState: {
            density: 'compact',
            expanded: true, //expand all groups by default
            grouping: [], //an array of columns to group by by default (can be multiple)
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [], //sort by state by default
        },
        muiToolbarAlertBannerChipProps: { color: 'primary' },
        muiTableContainerProps: { sx: { maxHeight: 700 } },
        renderTopToolbarCustomActions: ({ table }) => (

            <Box

                sx={{

                    display: 'flex',

                    gap: '16px',

                    padding: '8px',

                    flexWrap: 'wrap',

                }}

            >

                <Button

                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                    onClick={handleExportData}

                    startIcon={<FileDownloadIcon />}

                >

                    Export All Data

                </Button>

                {/* <Button

                    disabled={table.getPrePaginationRowModel().rows.length === 0}

                    //export all rows, including from the next page, (still respects filtering and sorting)

                onClick={() =>

                        handleExportRows(table.getPrePaginationRowModel().rows)

                    }

                    startIcon={<FileDownloadIcon />}

                >

                    Export All Rows

                </Button> */}

            </Box>

        ),
    });

    return <MaterialReactTable table={table} />;
};

export default DataFrame;
