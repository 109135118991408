import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Tabs } from "antd";
import InitBot from "./InitBot.tsx";

const defaultPanes = new Array(1).fill(null).map((_, index) => {
  const id = String(index + 1);
  return {
    label: `Session ${id}`,
    children: <InitBot id={index + 1} />,
    key: id,
  };
});

const Sherpa = () => {
  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [items, setItems] = useState(defaultPanes);
  const [loading, setLoading] = useState(true);
  const newTabIndex = useRef(0);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const add = () => {
    const newAcitveindex = newTabIndex.current++;
    const newActiveKey = `newTab${newAcitveindex}`;
    setItems([
      ...items,
      {
        label: `Session ${newAcitveindex + 2}`,
        children: <InitBot id={newAcitveindex + 2} />,
        key: newActiveKey,
      },
    ]);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } =
        newPanes[
          targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
        ];
      setActiveKey(key);
    }
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            loading={loading}
            style={{ minHeight: "80vh", overflow: "auto", height: "87vh" }}
            title="Sherpa"
          >
            <Tabs
              size="small"
              // hideAdd
              onChange={onChange}
              activeKey={activeKey}
              type="editable-card"
              onEdit={onEdit}
              items={items}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Sherpa;
