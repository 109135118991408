import React, { useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Upload,
  Select,
  Spin,
  Empty,
  Divider,
} from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./DocDiffrence.css";

const { Option } = Select;

const DocDiffrence = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [uploadVisible2, setUploadVisible2] = React.useState(true);
  const [spin, setSpin] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [fileList2, setFileList2] = React.useState([]);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [Response, setResponse] = React.useState({});
  const [selectedQuestion, setSelectedQuestion] =
    React.useState(" INTRODUCTION");
  const [Answer, setAnswer] = React.useState(null);

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleUploadChange2 = (file) => {
    setFileList2(file);
  };

  const handleChange = (value) => {
    setSelectedQuestion(value);
  };

  const handleIdentifyDiffrence = async () => {
    setSpin(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    const file = fileList?.file;
    const file2 = fileList2?.file;
    if (file) {
      const formData = new FormData();
      formData.append("file0", file);
      formData.append("file1", file2);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_RAG_GEN}/compare_difference`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          alert("Something went Wrong")
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setResponse(BackendResponse.data.Differences);
      } catch (error) {}
    }
    setTimeout(() => {
      setSpin(false);
      setContentVisible(true);
    }, 1500);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (fileList2 && fileList2.fileList && fileList2?.fileList.length > 0) {
      setUploadVisible2(false);
    } else {
      setUploadVisible2(true);
    }
  }, [fileList2]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  useEffect(() => {
    const SelectedAnswer =
      Response.length > 0 &&
      Response.find((option) => option?.question === selectedQuestion);
    setAnswer(SelectedAnswer);
  }, [selectedQuestion, Answer, Response]);

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={7}>
            <Card
              loading={loading}
              title="Documents Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="Doc-Diffrence-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload First File")}
                    </Upload>
                  </div>
                  <div className="Doc-Diffrence-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange2}
                      style={{ cursor: "pointer" }}
                      fileList={fileList2 && fileList2.fileList}
                    >
                      {uploadVisible2 && uploadButton("Upload Second file")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible && uploadVisible}
                    onClick={handleIdentifyDiffrence}
                    style={{ marginTop: "20px" }}
                    type="primary"
                    block
                  >
                    Identify Diffrences
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                Documents Input
              </p>
            </div>
          </Col>
        )}
        <Col span={isLeftNavVisible ? 23 : 17}>
          <Card
            loading={loading}
            style={{ minHeight: "80vh", overflow: "auto", height: "87vh" }}
            extra={
              contentVisible && fileList &&
              fileList.fileList &&
              fileList?.fileList.length > 0 && (
                <>
                  <label
                    style={{
                      fontWeight: "500",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    Select ID :
                  </label>
                  <Select
                    value={selectedQuestion}
                    style={{
                      width: 250,
                    }}
                    onChange={handleChange}
                  >
                    {Response &&
                      Response.map((value) => (
                        <Option
                          style={{ color: value.color }}
                          value={value.question}
                        >
                          {value.question}
                        </Option>
                      ))}
                  </Select>
                </>
              )
            }
            title="Documents Differences"
          >
            <div
              className="answer-content"
              style={{ whiteSpace: "pre-line", marginTop: "-20px" }}
            >
              {contentVisible &&
              fileList &&
              fileList.fileList &&
              fileList?.fileList.length > 0 ? (
                <>
                  <Row>
                    <Divider orientation="left" style={{ marginTop: "40px" }}>
                      {selectedQuestion}
                    </Divider>
                    <Col span={23}>
                      {Response.length > 0 &&
                        Answer &&
                        Answer.lineage.map((value, index) => (
                          <p
                            className="textArea"
                            style={{
                              color: "black",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ fontWeight: 500 }}>
                              {index + 1}):{" "}
                            </span>
                            {value}
                          </p>
                        ))}
                    </Col>
                  </Row>
                </>
              ) : spin ? (
                <Spin
                  style={{ marginTop: "150px" }}
                  tip="Loading..."
                  size="large"
                >
                  <div className="content" />
                </Spin>
              ) : (
                <Empty
                  style={{ marginTop: "150px" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DocDiffrence;
