import React, { useEffect, useState, useRef } from "react";
import { Button, Input, Card, Row, Col, Form, Spin, Tooltip } from "antd";
import { AudioOutlined, PauseOutlined, CopyOutlined } from "@ant-design/icons";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "./Cognito.css";

const Cognito = () => {
  const [loading, setLoading] = React.useState(false);
  const [spin, setSpin] = React.useState(false);
  // const [contentVisible, setContentVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [question, setQuestion] = React.useState("");
  const [curAnswer, setCurAnswer] = React.useState<any>(null);
  const [showMicroPhone, setShowMicroPhone] = useState(true);
  const [copied, setCopied] = useState(false);
  const contentRef = useRef(null);
  const {
    transcript,
    browserSupportsSpeechRecognition,
    // resetTranscript,
    listening,
  } = useSpeechRecognition();

  // const bg = "To provide you with an accurate answer, I need to clarify what metric we're using to rank the \"top\" states in the U.S. The term \"top\" could refer to various factors such as population, economy, education, healthcare, quality of life, geography, tourism, or other metrics.\n\nFor example, if we're looking at population, the top five most populous states in the U.S. (based on the most recent U.S. Census data as of my knowledge cutoff in early 2023) are:\n\n1. California\n2. Texas\n3. Florida\n4. New York\n5. Pennsylvania\n\nHowever, if you are looking for top states in a different category, such as economic output, the answer could be different. Please specify the criteria you want to use to determine the \"top\" states, and I can provide a more accurate and fact-checked response."
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    if (!question.trim()) return;
    setSpin(true);
    setLoading(true);
    // setCurAnswer("");
    try {
      const Response = await fetch(
        `${process.env.REACT_APP_CODE_GEN}/cognito/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ input_query: question }),
        }
      );
      if (!Response.ok) {
        alert("Something went Wrong");
        throw new Error(`HTTP error! status: ${Response.status}`);
      }
      const BackendResponse: any = await Response.json();
      // const updatedbackendResponse = BackendResponse.substring(
      //   1,
      //   BackendResponse.length - 1
      // );
      // setContentVisible(true);
      let copycurAnswer: any = [];
      if (curAnswer == null) {
        copycurAnswer = [BackendResponse];
      } else {
        copycurAnswer = [BackendResponse, ...curAnswer];
      }
      setCurAnswer(copycurAnswer);
      console.log("copycurAnswer", copycurAnswer);
      setSpin(false);
      setLoading(false);
    } catch (error) {
      setSpin(false);
      setLoading(false);
    }
    setQuestion("");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    console.log("curAnswer", curAnswer);
  }, [curAnswer, spin]);

  useEffect(() => {
    if (listening) {
      setQuestion(transcript);
    }
  }, [transcript, listening]);

  const startListeningg = () => {
    setShowMicroPhone(false);
    SpeechRecognition.startListening({ language: "en-IN" });
  };

  const stopListeningg = () => {
    setShowMicroPhone(true);
    SpeechRecognition.stopListening();
  };

  useEffect(() => {
    if (!listening) {
      setShowMicroPhone(true);
    }
  }, [listening]);

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  const copyToClipboard = () => {
    const textToCopy = contentRef.current.textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        console.log("Could not Copy");
      });
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            style={{ minHeight: "80vh", overflow: "auto", height: "87vh" }}
            title="Cognito"
          >
            <Form form={form} layout="vertical">
              <Form.Item
                label="Enter your question:"
                name="question"
                // rules={[
                //   { required: true},
                // ]}
                style={{
                  fontWeight: "bold",
                  color: "darkblue",
                  marginBottom: "20px",
                }}
              >
                <Input.TextArea
                  value={question && question}
                  onChange={handleQuestionChange}
                  placeholder="Ask me anything"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  style={{ fontSize: "16px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={handleSubmit}
                  disabled={question.length === 0}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Generate Response
                </Button>
                {showMicroPhone && (
                  <Tooltip placement="bottom" title={"Search with your voice"}>
                    <Button
                      // disabled={question.length === 0}
                      // loading={loading}
                      style={{ marginLeft: "15px" }}
                      type="primary"
                      shape="circle"
                      onClick={startListeningg}
                      icon={<AudioOutlined />}
                    />
                  </Tooltip>
                )}
                {!showMicroPhone && (
                  <Tooltip placement="bottom" title={"Pause"}>
                    <Button
                      // disabled={question.length === 0}
                      // loading={loading}
                      style={{ marginLeft: "15px" }}
                      type="primary"
                      shape="circle"
                      onClick={stopListeningg}
                      icon={<PauseOutlined />}
                    />
                  </Tooltip>
                )}
                <span style={{ marginLeft: "15px" }}>{transcript}</span>
              </Form.Item>
            </Form>
            <Spin tip="Loading..." size="large" spinning={spin}>
              {curAnswer?.length > 0 && (
                <Row>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <Button
                      type="dashed"
                      size="small"
                      icon={<CopyOutlined />}
                      onClick={copyToClipboard}
                    >
                      {copied ? "Copied" : "Copy"}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: "10px" }}>
                <Col span={24}>
                  {curAnswer?.length > 0 ? (
                    <div
                      style={{
                        padding: "10px",
                        // backgroundColor: "#f5f5f5",
                        // backgroundColor: "#0c0c46",
                        height: "40vh",
                        overflow: "auto",
                        border: "1px solid #f5f5f5",
                        borderRadius: "8px",
                      }}
                    >
                      <>
                        {curAnswer?.map((el, key) => (
                          <div className="respDiv" key={key}>
                            {el.split("\n").map((str) => (
                              <p ref={contentRef}>{str}</p>
                            ))}
                          </div>
                        ))}
                      </>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Cognito;
