
import React, { useState } from 'react';
import AceEditor from "react-ace";
import './editor.css';

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

const InitEditor = (Props) => {

    const editorContent = Props.content;

    const onChange=e=>{
        console.log(e);
        Props.callback(e);
    }

    return (
        <> 
            <AceEditor
             placeholder="Placeholder Text"
             mode="python"
             theme="monokai"
             name="blah2"
             // onLoad={this.onLoad}
             onChange={onChange}   
             fontSize={14}
             showPrintMargin={true}
             showGutter={true}
             highlightActiveLine={true}
             value={editorContent}
             editorProps={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
             }}
             style={{width:'100%',height:'250px'}}  
            />
        </>
    )
}
export default InitEditor;