import React from "react";
import { Space } from "antd";
import { Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import AppLayout from "./Components/AppLayout/AppLayout.tsx";
import PatientNarrative from "./Components/PatientNarrative/PatientNarratives.tsx";
import SAPInsights from "./Components/SAPInsights/SAPInsights.tsx";
import Cognito from "./Components/Cognito/Cognito.tsx";
import Sherpa from "./Components/Sherpa/Sherpa.tsx";
import PILGenerator from "./Components/PILGenerator/PILGenerator.tsx";
import CSRGenerator from "./Components/CSRGenerator/CSRGenerator.tsx";
import CodeGeneration from "./Components/CodeGeneration/CodeGeneration.tsx";
import DocDiffrence from "./Components/DocDiffrence/DocDiffrence.tsx";
import RAG from "./Components/RAG/RAG.tsx";
import PPTGenerator from "./Components/PPTGenerator/PPTGenerator.tsx";
import login_img from "./Assets/login.png";
import "./Components/Login.css"
import UpdatedCSR from "./Components/UpdatedCSR/UpdatedCSR.tsx";

const App = () => (
  <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/patient-narratives" element={<PatientNarrative />} />
        <Route path="/sap-insights" element={<SAPInsights />} />
        <Route path="/cognito" element={<Cognito />} />
        <Route path="/sherpa" element={<Sherpa />} />
        <Route path="/pil-generator" element={<PILGenerator />} />
        {/* <Route path="/csr-generator" element={<CSRGenerator />} /> */}
        <Route path="/csr-generator" element={<UpdatedCSR />} />
        <Route path="/code-generator" element={<CodeGeneration />} />
        <Route path="/doc-difference" element={<DocDiffrence />} />
        <Route path="/rag" element={<RAG />} />
        <Route path="/ppt-generator" element={<PPTGenerator />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/" element={<Login />} />
    </Routes>
  </Space>
);

export default App;

function Login() {
  const onFinish = (values) => {
    console.log("Success:", values);
    window.location.href = "/patient-narratives";
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src={login_img} alt="Login" />
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">
            <img
              alt="Gen AI"
              height={"60px"}
              src={"https://www.saama.com/wp-content/uploads/saama_logo.svg"}
            ></img>
          </p>
          <p>Gen AI Application</p>
          <Form.Item
            name="username"
            rules={[
              {
                message: "Please input your  username!",
              },
            ]}
          >
            <Input placeholder="Username" defaultValue="saama" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" defaultValue="saama" />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              span: 16,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{}}>
            <Button
              type="primary"
              className="login-form-button"
              htmlType="submit"
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/home">Go to the home page</Link>
      </p>
    </div>
  );
}
