import React from 'react'

import { CodeOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Radio, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import InitEditor from './Editor';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import DynamicTable from './tableComponent';
import DataFrame from './dataFrame';
 
const InitBotForm = () => {
    const [outputPreviewMode, setOutputPreviewMode] = useState('Raw');
    const [editorCodedata, setEditorCodedata] = useState(null);
    const [finaloutPut, setFinaloutPut] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [userInput, SetUserInput] = useState("What are the vital signs recorded in this study?");

    useEffect(() => {
        console.log("init");
        setTimeout(() => {
            setLoading(false);
        }, 10);
    }, []);

    const handleUserInput = e => {
        SetUserInput(e.target.value);

        console.log(userInput);
    }

    const handleCallback = value => {
        setEditorCodedata(value);
        console.log(editorCodedata);
    }

    const getInputQueryData = () => {

        setEditorCodedata(null);
        setFinaloutPut(null);

        if (userInput != null) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_CODE_GEN}/generate_code/`, {
                method: "POST",
                headers: {
                    'accept': 'application/json',
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "input_query": userInput
                })
            })
                .then((response) => response.json())
                .then((actualData) => {
                    setEditorCodedata(actualData);
                    setError(null);
                    setLoading(false);
                }).catch((err) => {
                    // setError(err.message);
                    setError("failed to fetch - retry with new or rephrase the existing query");
                    setEditorCodedata(null);
                    setLoading(false);
                })
        }

    }

    const getRunQueryData = () => {
        if (userInput != null && editorCodedata != null) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_CODE_GEN}/run_code/`, {
                method: "POST",
                headers: {
                    'accept': 'application/json',
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "current_input": userInput,
                    "code": editorCodedata
                })
            })
                .then((response) => response.json())
                .then((actualData) => {
                    setFinaloutPut(actualData);
                    console.log(finaloutPut);
                    setError(null);

                })
                .catch((err) => {
                    setError(err.message);
                    setError("failed to fetch - retry with new or rephrase the existing query");

                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }

    const onFinalOutputPreviewChange = (e) => {
        setOutputPreviewMode(e.target.value);
    }
 
    return (
        <><Spin spinning={loading}>
            <Row gutter={[16, 8]} className='BotinputForm' style={{ marginBottom: '5px' }}>
                <Col span={1} style={{ textAlign: 'end', alignSelf: 'center' }}>
                    <Tooltip title="Generate Code" className='BotSeachAction'>
                        <Button type="dashed" onClick={getInputQueryData} shape="circle" icon={<ThunderboltOutlined />} />
                    </Tooltip>
                </Col>
                <Col span={23} >
                    <div>
                        <TextArea rows={2} style={{ width: '100%' }} value={userInput} onChange={handleUserInput} placeholder="input Query  " />
                    </div>
                </Col>
            </Row>
            {editorCodedata != null &&
                <Row gutter={[16, 8]} className='BotinputForm' >
                    <Col span={1} style={{ textAlign: 'end' }}>
                        <Tooltip title="Run Code" className='BotSeachAction'>
                            <Button type="dashed" onClick={getRunQueryData} shape="circle" icon={<CodeOutlined />} />
                        </Tooltip>
                    </Col>
                    <Col span={23} >
                        <InitEditor content={editorCodedata} callback={handleCallback} />
                    </Col>
                </Row>
            }
            {/* final Out Put */}
            {(finaloutPut != null) && (
                <Row gutter={[16, 8]}>
                    <Col span={1}></Col>
                    <Col span={23} style={{ paddingTop: "10px" }}>
                        <Radio.Group size='small' value={outputPreviewMode} onChange={onFinalOutputPreviewChange}>
                            <Radio.Button value="Raw">Raw</Radio.Button>
                            <Radio.Button value="Preview">Preview</Radio.Button>
                            <Radio.Button value="Visualize">Visualize</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className='chart-Container'>
                        <div style={{ height: "400px", border: "1px dotted black", overflow: 'auto' }} >
                            {(finaloutPut != null && outputPreviewMode == "Raw") &&
                                <ul>{finaloutPut}</ul>
                            }

                            {(finaloutPut != null && outputPreviewMode == "Preview") && <InitPreview data={finaloutPut} />}

                            {(finaloutPut != null && outputPreviewMode == "Visualize") && <Graph data={finaloutPut} />}

                        </div>
                    </Col>
                </Row>
            )}
            </Spin>
        </>
    )
}

export default InitBotForm;


const InitPreview = ({ data }) => {
    let tabledata: any = [];
    let parseData = JSON.parse(data);
    if (typeof parseData === 'object' && !Array.isArray(parseData) && parseData !== null) {
        tabledata.push(parseData);
    } else if (Array.isArray(parseData)) {
        tabledata = parseData;
    }

    return <DataFrame TableData={tabledata} />;
}

const Graph = ({ data }) => {

    const parseData = JSON.parse(data);
    let keys: any = [];
    let optiondata = [];
    try {
        if (Array.isArray(parseData) == false) {
            keys = Object.keys(parseData);

            if (keys.length > 0) {
                for (const property in parseData) {
                    console.log(`${property}: ${parseData[property]}`);
                    optiondata.push({ name: property, y: parseData[property] });
                }
                console.log(optiondata);
            }

        }
    } catch (e) {
        console.log(e)
        // throw Error(e);
    }


    const options = {
        chart: {
            type: 'pie'
        },
        title: {
            text: null
        },
        series: [{
            data: optiondata
        }],
        credits:{
            enabled:false
        }
    }

    return (
        <div className='chart-Container'>
            {optiondata.length > 0 && <HighchartsReact containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts}
                options={options}
            />}
        </div>
    )
}

const IsJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
