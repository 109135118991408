import React, { useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Upload,
  Tooltip,
  Empty,
  Spin,
  Divider,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./PPTGenerator.css";
import PPT from "./PPT_PROTOC.pptx";

const { TextArea } = Input;

const PPTGenerator = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [uploadVisible2, setUploadVisible2] = React.useState(true);
  const [spin, setSpin] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [fileList2, setFileList2] = React.useState([]);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [editableAnswers, setEditableAnswers] = React.useState({});
  const [Response, setResponse] = React.useState({});

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleUploadChange2 = (file) => {
    setFileList2(file);
  };

  const handleAnswerChange = (e, key) => {
    setEditableAnswers({
      ...editableAnswers,
      [key]: e.target.value,
    });
  };

  const handleGeneratePPT = async () => {
    setSpin(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    const file = fileList?.file;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_RAG_GEN}/ppt_generator`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          alert("Something went Wrong");
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setResponse(BackendResponse.data);
      } catch (error) {}
    }
    setTimeout(() => {
      setSpin(false);
    }, 1500);
    setContentVisible(true);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (fileList2 && fileList2.fileList && fileList2?.fileList.length > 0) {
      setUploadVisible2(false);
    } else {
      setUploadVisible2(true);
    }
  }, [fileList2]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={7}>
            <Card
              loading={loading}
              title="PPT Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="Doc-Diffrence-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload Data")}
                    </Upload>
                  </div>
                  <div className="PPT-Generator-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange2}
                      style={{ cursor: "pointer" }}
                      fileList={fileList2 && fileList2.fileList}
                    >
                      {uploadVisible2 && uploadButton("Upload PPT Template")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleGeneratePPT}
                    style={{ marginTop: "20px" }}
                    type="primary"
                    block
                  >
                    Generate PPT
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                PPT Input
              </p>
            </div>
          </Col>
        )}

        <Col span={isLeftNavVisible ? 23 : 17}>
          <Card
            loading={loading}
            style={{ minHeight: "80vh", overflow: "auto", height: "87vh" }}
            extra={
              <>
                <Tooltip placement="bottom" title={"Download PPT"}>
                  <Button
                    disabled={!contentVisible}
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: "-60px", cursor: "pointer" }}
                    href={PPT}
                    download
                  />             
                </Tooltip>
              </>
            }
            title="Generated PPT"
          >
            <div
              className="answer-content"
              style={{ whiteSpace: "pre-line", marginTop: "-20px" }}
            >
              {contentVisible &&
              fileList &&
              fileList.fileList &&
              fileList?.fileList.length > 0 ? (
                <>
                  {Response?.slides.map((val) => (
                    <>
                      <Divider orientation="left" style={{ marginTop: "30px" }}>
                        {val.title}
                      </Divider>
                      <div className="p-3">
                        {val.content.length > 0 &&
                          val?.content.map((item, index) => {
                            return (
                              <div
                                style={{ padding: "2px", marginLeft: "60px" }}
                              >
                                <TextArea
                                  style={{ textAlign: "left" }}
                                  className={`textarea level${
                                    item.level != null ? item.level : ""
                                  }`}
                                  autoSize
                                  value={editableAnswers[index] || item.text}
                                  onChange={(e) => handleAnswerChange(e, index)}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ))}
                </>
              ) : spin ? (
                <Spin
                  style={{ marginTop: "150px" }}
                  tip="Loading..."
                  size="large"
                >
                  <div className="content" />
                </Spin>
              ) : (
                <Empty
                  style={{ marginTop: "150px" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PPTGenerator;
