import React, { useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Upload,
  Select,
  Form,
  Spin,
} from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./RAG.css";

const RAG = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [dorpDownVisible, setDorpDownVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [form] = Form.useForm();
  const [question, setQuestion] = React.useState("");
  const [spin, setSpin] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [Answer, setAnswer] = React.useState("");
  // const [filesList, setFilesList] = React.useState([]);

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleGenerateResponse = () => {
    setSpin(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    setTimeout(() => {
      setSpin(false);
      setContentVisible(true);
    }, 1500);
  };

  // const fetchDataList = async () => {
  //   try {
  //     const Response = await fetch(
  //       `${process.env.REACT_APP_CODE_GEN}/askmeanything/`,
  //       {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );
  //     if (!Response.ok) {
  //       alert("Something went Wrong")
  //       throw new Error(`HTTP error! status: ${Response.status}`);
  //     }
  //     const BackendResponse = await Response.text();
  //     setFilesList(BackendResponse);
  //   } catch (error) {}
  // };

  const handleGenerateList = async () => {
    const file = fileList?.file;
    try {
      const Response = await fetch(
        `${process.env.REACT_APP_CODE_GEN}/askmeanything/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ input_query: question, fileName: file }),
        }
      );
      if (!Response.ok) {
        alert("Something went Wrong");
        throw new Error(`HTTP error! status: ${Response.status}`);
      }
      const BackendResponse = await Response.text();
      const updatedbackendResponse = BackendResponse.substring(
        1,
        BackendResponse.length - 1
      );
      setAnswer(updatedbackendResponse);
    } catch (error) {}
    setTimeout(() => {
      setDorpDownVisible(true);
    }, 1500);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  // useEffect(() => {
  //   fetchDataList();
  // }, []);

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={7}>
            <Card
              loading={loading}
              title="RAG Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="RAG-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload Data")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleGenerateList}
                    style={{ marginTop: "70px" }}
                    type="primary"
                    block
                  >
                    Generate List
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                RAG Input
              </p>
            </div>
          </Col>
        )}

        <Col span={isLeftNavVisible ? 23 : 17}>
          <Card
            loading={loading}
            style={{ minHeight: "80vh", overflow: "auto", height: "86vh" }}
            extra={
              dorpDownVisible && (
                <>
                  <label
                    style={{
                      fontWeight: "500",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    Select File:
                  </label>
                  <Select
                    defaultValue="File 1"
                    style={{
                      width: 180,
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "1",
                        label: "File 1",
                      },
                      {
                        value: "2",
                        label: "File 2",
                      },
                      {
                        value: "3",
                        label: "File 3",
                      },
                      {
                        value: "4",
                        label: "File 4",
                      },
                      {
                        value: "5",
                        label: "File 5",
                      },
                      {
                        value: "6",
                        label: "File 6",
                      },
                    ]}
                  />
                </>
              )
            }
            title="Generated RAG"
          >
            <Form form={form} layout="vertical">
              <Form.Item
                label="Enter your question:"
                name="question"
                // rules={[
                //   { required: true, message: "Please input your question" },
                // ]}
                style={{
                  fontWeight: "bold",
                  color: "darkblue",
                  marginBottom: "20px",
                }}
              >
                <Input.TextArea
                  value={question}
                  onChange={handleQuestionChange}
                  placeholder="Type your question here"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  style={{ fontSize: "16px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={question.length === 0}
                  onClick={handleGenerateResponse}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Generate Response
                </Button>
              </Form.Item>
            </Form>
            {/* <div>
                {loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  curAnswer && (
                    <div
                      style={{
                        backgroundColor: "#f6ffed",
                        borderColor: "#b7eb8f",
                        color: "green",
                        padding: "20px",
                        marginTop: "20px",
                        borderRadius: "20px",
                      }}
                    >
                      <p style={{ wordSpacing: "5px" }}>
                        {curAnswer.split(".")}
                      </p>
                    </div>
                  )
                )}
            </div> */}
            <Row style={{ marginTop: "10px" }}>
              <Col span={24}>
                {contentVisible ? (
                  <div
                    style={{
                      padding: "15px",
                      backgroundColor: "#0c0c46",
                      height: "40vh",
                      overflow: "auto",
                    }}
                  >
                    <p
                      className="textArea"
                      style={{
                        color: "white",
                        textAlign: "left",
                        marginTop: "-5px",
                      }}
                    >
                      Subject ID: 053001 was Abacavir is used to treat HIV
                      (human immunodeficiency virus) infection Abacavir contains
                      the active ingredient abacavir. Abacavir belongs to a
                      group of anti-retroviral medicines called nucleoside
                      analogue reverse transcriptase inhibitors (NRTIs) Abacavir
                      does not completely cure HIV infection; it reduces the
                      amount of virus in your body, and keeps it at a low level
                      Subject ID: 053001 was Abacavir is used to treat HIV
                      (human immunodeficiency virus) infection Abacavir contains
                      the active ingredient abacavir.it reduces the amount of
                      virus in your body, and keeps it at a low level Subject
                      ID: 053001 was Abacavir is used to treat HIV (human
                      immunodeficiency virus) infection Abacavir contains the
                      active ingredient abacavir. it reduces the amount of virus
                      in your body, and keeps it at a low level Subject ID:
                      053001 was Abacavir is used to treat HIV (human
                      immunodeficiency virus)
                    </p>
                    <p
                      className="textArea"
                      style={{
                        color: "white",
                        textAlign: "left",
                        marginTop: "-5px",
                      }}
                    >
                      Subject ID: 053001 was Abacavir is used to treat HIV
                      (human immunodeficiency virus) infection Abacavir contains
                      the active ingredient abacavir. Abacavir belongs to a
                      group of anti-retroviral medicines called nucleoside
                      analogue reverse transcriptase inhibitors (NRTIs) Abacavir
                      does not completely cure HIV infection.
                    </p>
                  </div>
                ) : spin ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : null}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RAG;
