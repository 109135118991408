import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Upload,
  Space,
  Tooltip,
  Collapse,
  theme,
  Avatar,
  Drawer,
} from "antd";
import {
  LikeOutlined,
  DislikeOutlined,
  CopyOutlined,
  EditOutlined,
  DownloadOutlined,
  CaretRightOutlined,
  UploadOutlined,
  UndoOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import Markdown from "react-markdown";
import LoadImage from "../../Assets/Audit.gif";
import Meta from "antd/es/card/Meta";
import CSRContent from "./CSR_Content";
import "./UpdatedCSR.css";
const { Panel } = Collapse;

const UpdatedCSR = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [uploadVisible2, setUploadVisible2] = React.useState(true);
  const [uploadVisible3, setUploadVisible3] = React.useState(true);
  const [uploadVisible4, setUploadVisible4] = React.useState(true);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [fileList2, setFileList2] = React.useState([]);
  const [fileList3, setFileList3] = React.useState([]);
  const [fileList4, setFileList4] = React.useState([]);
  const [editable, setEditable] = React.useState(false);
  const [GeneratedCSR, setGeneratedCSR] = React.useState([]);
  const inputRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [sourceLoading, setSourceLoading] = React.useState(true);

  const showSourceLoading = () => {
    setOpen(true);
    setSourceLoading(true);
    setTimeout(() => {
      setSourceLoading(false);
    }, 2000);
  };

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
          cursor: "pointer",
        }}
      >
        <UploadOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleUploadChange2 = (file) => {
    setFileList2(file);
  };

  const handleUploadChange3 = (file) => {
    setFileList3(file);
  };

  const handleUploadChange4 = (file) => {
    setFileList4(file);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (fileList2 && fileList2.fileList && fileList2?.fileList.length > 0) {
      setUploadVisible2(false);
    } else {
      setUploadVisible2(true);
    }
  }, [fileList2]);

  useEffect(() => {
    if (fileList3 && fileList3.fileList && fileList3?.fileList.length > 0) {
      setUploadVisible3(false);
    } else {
      setUploadVisible3(true);
    }
  }, [fileList3]);

  useEffect(() => {
    if (fileList4 && fileList4.fileList && fileList4?.fileList.length > 0) {
      setUploadVisible4(false);
    } else {
      setUploadVisible4(true);
    }
  }, [fileList4]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const handleEdit = () => {
    setEditable(!editable);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  };

  const fetchDataList = async () => {
    const file = fileList?.file;
    const file2 = fileList2?.file;
    const file3 = fileList3?.file;
    if (file) {
      const formData = new FormData();
      formData.append("protocol", file);
      formData.append("template", file2);
      formData.append("tlf", file3);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_CSR_APP}/generate_csr`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          // alert("Something went Wrong");
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setGeneratedCSR(BackendResponse.csr_content);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("Something went Wrong");
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    fetchDataList();
    // setTimeout(() => {
    //   setLoading(false);
    // }, 5000);
    setContentVisible(true);
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleDownload = () => {
    fetch(`${process.env.REACT_APP_CSR_APP}/download_csr_document`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/octet-stream",
      }),
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `CSR_${new Date()}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <div className="specific_page">
      <Row gutter={16}>
        <Col span={6}>
          <Card
            style={{ minHeight: "100%" }}
            styles={{ body: { padding: "10px 20px" } }}
            title="CSR Input files"
            extra={
              <Tooltip title={"Clear Input"}>
                <Avatar
                  onClick={() => {
                    setFileList([]);
                    setFileList2([]);
                    setFileList3([]);
                    setFileList4([]);
                  }}
                  icon={<UndoOutlined />}
                  style={{ background: "transparent", color: "black" }}
                ></Avatar>
              </Tooltip>
            }
          >
            <Row justify="center" align="middle">
              <Col xs={24}>
                <span className="UploadHeader"> Study Protocol:</span>
                <div className="CSR-Generator-Upload">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    onChange={handleUploadChange}
                    style={{ cursor: "pointer", width: "150px" }}
                    fileList={fileList && fileList.fileList}
                  >
                    {uploadVisible &&
                      uploadButton("Click or drag file to upload")}
                  </Upload>
                </div>
                <span className="UploadHeader">CSR Template:</span>
                <div className="CSR-Generator-Upload">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    onChange={handleUploadChange2}
                    style={{ cursor: "pointer" }}
                    fileList={fileList2 && fileList2.fileList}
                  >
                    {uploadVisible2 &&
                      uploadButton("Click or drag file to upload")}
                  </Upload>
                </div>
                <span className="UploadHeader">
                  Tables, Listings & Figures:
                </span>
                <div className="CSR-Generator-Upload">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    onChange={handleUploadChange3}
                    style={{ cursor: "pointer" }}
                    fileList={fileList3 && fileList3.fileList}
                  >
                    {uploadVisible3 &&
                      uploadButton("Click or drag file to upload")}
                  </Upload>
                </div>
                <span className="UploadHeader">SDTM Datasets:</span>
                <div className="CSR-Generator-Upload">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    onChange={handleUploadChange4}
                    style={{ cursor: "pointer" }}
                    fileList={fileList4 && fileList4.fileList}
                  >
                    {uploadVisible4 &&
                      uploadButton("Click or drag file to upload")}
                  </Upload>
                </div>
                <Button
                  disabled={uploadVisible4}
                  onClick={handleSubmit}
                  style={{ marginTop: "10px" }}
                  type="primary"
                  block
                  className="login-form-button"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card
            loading={loading}
            style={{ overflow: "auto", minHeight: "100%" }}
            title="Generated Clinical Study Report"
            className={`${isFullScreen ? "ant-card-bordered_fullscreen" : ""}`}
            extra={
              contentVisible &&
              !loading &&
              fileList &&
              fileList.fileList &&
              fileList?.fileList.length > 0 ? (
                <>
                  <Space.Compact className="actionsButton">
                    <Tooltip title={"Download"}>
                      <Button
                        // href={pdf}
                        download
                        icon={
                          <DownloadOutlined
                          onClick={handleDownload}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  </Space.Compact>
                  {isFullScreen ? (
                    <Tooltip title={"Exit Full Screen"}>
                      <FullscreenExitOutlined
                        onClick={handleFullScreen}
                        style={{
                          fontSize: "19px",
                          marginLeft: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Full Screen"}>
                      <FullscreenOutlined
                        onClick={handleFullScreen}
                        style={{
                          fontSize: "19px",
                          marginLeft: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : null
            }
          >
            <Row>
              <Col span={!contentVisible ? 24 : 24}>
                <div
                  className="answer-content"
                  style={{
                    // whiteSpace: "pre-line",
                    // height: "85vh",
                    marginTop: "-20px",
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {contentVisible &&
                  !loading &&
                  fileList &&
                  fileList.fileList &&
                  fileList?.fileList.length > 0 ? (
                    <>
                      <Collapse
                        accordion={true}
                        bordered={false}
                        collapsible="icon"
                        size="middle"
                        defaultActiveKey={["0"]}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        expandIconPosition="end"
                        style={{
                          background: token.colorBgContainer,
                        }}
                      >
                        {GeneratedCSR.map((item, index) => (
                          <Panel
                            header={item?.title}
                            key={index}
                            style={panelStyle}
                            extra={
                              <Space.Compact className="actionsButton">
                                <Tooltip title={"Edit"}>
                                  <Button
                                    className="section-action-button"
                                    onClick={handleEdit}
                                    icon={<EditOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Copy"}>
                                  <Button
                                    className="section-action-button"
                                    icon={<CopyOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Add a positive review status"}>
                                  <Button
                                    className="section-action-button"
                                    icon={<LikeOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Add a negative review status"}>
                                  <Button
                                    className="section-action-button"
                                    icon={<DislikeOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip
                                  title={"Information"}
                                  onClick={() => showSourceLoading()}
                                >
                                  <Button
                                    className="section-action-button"
                                    icon={<InfoCircleOutlined />}
                                  ></Button>
                                </Tooltip>
                              </Space.Compact>
                            }
                          >
                            <div ref={inputRef} contentEditable={editable}>
                              {/* <Markdown>{item?.content}</Markdown> */}
                              <CSRContent content={item?.content} />
                            </div>
                          </Panel>
                        ))}
                      </Collapse>

                      {open === true && (
                        <>
                          {/* <Button type="primary" onClick={showSourceLoading}>
                            Open Drawer
                          </Button> */}
                          <Drawer
                            closable
                            destroyOnClose
                            title={"Source Details"}
                            placement="right"
                            open={open}
                            loading={sourceLoading}
                            onClose={() => setOpen(false)}
                          >
                            {/* <Button
                            type="primary"
                            style={{
                              marginBottom: 16,
                            }}
                            onClick={showSourceLoading}
                          >
                            Reload
                          </Button> */}
                            <h4 style={{ marginTop: 0 }}>1. TITLE PAGE</h4>
                            <Card
                              style={{
                                width: 300,
                                marginBottom: 10,
                              }}
                            >
                              <Meta
                                className="src-header"
                                avatar={<FileTextOutlined />}
                                title="PL3397-A-A103 - Protocol.pdf"
                                description={
                                  <div
                                    style={{
                                      color: "#474747",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Page 1 to 19
                                  </div>
                                }
                              />
                            </Card>

                            <Card
                              style={{
                                width: 300,
                              }}
                            >
                              <Meta
                                className="src-header"
                                avatar={<FileTextOutlined />}
                                title="SDTM: TS.XPT (Trial summary)"
                                description={
                                  <div
                                    style={{
                                      color: "#474747",
                                      fontWeight: 500,
                                    }}
                                  >
                                    TSPARMCD = SSTDTC <br /> TSPARMCD = SENDTC
                                  </div>
                                }
                              />
                            </Card>
                          </Drawer>
                        </>
                      )}
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={LoadImage}
                        style={{ width: "450px" }}
                        alt="No Data"
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {!isFullScreen && (
              <span className="footer-note">
                Powered by Saama's GenAI model using Daiichi Sankyo Global
                English-Language House Style Guide.
              </span>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatedCSR;
