import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Markdown from "react-markdown";

const CSRContent = ({ content }) => {
  const [Response, setResponse] = useState([]);
  console.log(
    "Contenttt",
    content.replaceAll("NEST PROJECT||\n", "").split("||||")
  );

  useEffect(() => {
    const sepratedString = content
      .replaceAll("NEST PROJECT||\n", "")
      .split("||||");
    setResponse(sepratedString);
  }, []);

  const isTable = (text) => {
    return text.includes("||");
  };

  return (
    <>
      {Response.map((content, index) => {
        return (
          <>
            {isTable(content) ? (
              <SampleTable content={content} />
            ) : (
              <Markdown>{content}</Markdown>
            )}
          </>
        );
      })}
    </>
  );
};

export default CSRContent;

const SampleTable = ({ content }) => {
  const parseContent = (content) => {
    const lines = content.split("||");
    console.log("Contenttbale", lines);
    const startIndex = lines.findIndex((line) =>
      line.includes(
        "--------------------------------------------------------------------------------------"
      )
    );
    const endIndex = lines.findLastIndex((line) =>
      line.includes(
        "--------------------------------------------------------------------------------------"
      )
    );

    if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
      return { columns: [], dataSource: [] };
    }

    const tableData = lines
      .slice(startIndex + 1, endIndex)
      .filter((line) => line.trim() !== "");

    // Define columns
    const columns = [
      { title: "Term", dataIndex: "term", key: "term" },
      { title: "A: Drug X", dataIndex: "drugX", key: "drugX" },
      { title: "B: Placebo", dataIndex: "placebo", key: "placebo" },
      { title: "C: Combination", dataIndex: "combination", key: "combination" },
      { title: "All Patients", dataIndex: "allPatients", key: "allPatients" },
    ];

    // Parse data rows
    const dataSource = tableData.map((line, index) => {
      const [term, drugX, placebo, combination, allPatients] =
        line.split(/\s{2,}/);
      return {
        key: index,
        term: term ? term.trim() : "",
        drugX: drugX ? drugX.trim() : "",
        placebo: placebo ? placebo.trim() : "",
        combination: combination ? combination.trim() : "",
        allPatients: allPatients ? allPatients.trim() : "",
      };
    });

    return { columns, dataSource };
  };
  const { columns, dataSource } = parseContent(content);

  return (
    <>
      <Table
        className="CSR_Table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: true }}
        size="small"
      />
    </>
  );
};
