import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  DeploymentUnitOutlined,
  MoonFilled,
  SunFilled,
  FileSearchOutlined,
  FilePptOutlined,
  ReadOutlined,
  ContainerOutlined,
  ProfileOutlined,
  BarChartOutlined,
  CommentOutlined,
  ProductOutlined,
  RobotOutlined,
  LogoutOutlined,
  // BellOutlined,
  // CodeSandboxOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Layout, Menu, theme, Row, Col, Drawer, Divider } from "antd";
import saamaLogo from "../../Assets/Saama.png";
import saamaLogoLight from "../../Assets/SaamaLight.png";

const { Header, Content, Sider } = Layout;

const items: MenuProps["items"] = [
  {
    key: "k1",
    linkpath: "/patient-narratives",
    label: <Link to="/patient-narratives"> Patient Narratives</Link>,
    icon: React.createElement(ProfileOutlined),
  },
  {
    key: "k2",
    linkpath: "/sap-insights",
    label: <Link to="/sap-insights">SAP Insights</Link>,
    icon: React.createElement(BarChartOutlined),
  },
  {
    key: "k3",
    linkpath: "/cognito",
    label: <Link to="/cognito">Cognito</Link>,
    icon: React.createElement(CommentOutlined),
  },
  {
    key: "k4",
    linkpath: "/sherpa",
    label: <Link to="/sherpa">Sherpa</Link>,
    icon: React.createElement(RobotOutlined),
  },
  {
    key: "k5",
    linkpath: "/pil-generator",
    label: <Link to="/pil-generator">PIL Generator</Link>,
    icon: React.createElement(BarChartOutlined),
  },
  {
    key: "k6",
    linkpath: "/csr-generator",
    label: <Link to="/csr-generator">CSR Generator</Link>,
    icon: React.createElement(ContainerOutlined),
  },
  // {
  //   key: "k8",
  //   linkpath: "/code-generator",
  //   label: <Link to="/code-generator">Code Generator</Link>,
  //   icon: React.createElement(CodeSandboxOutlined),
  // },
  {
    key: "k7",
    label: "DOC AI",
    icon: React.createElement(ProductOutlined),
    children: [
      {
        key: "k71",
        linkpath: "/doc-difference",
        label: <Link to="/doc-difference">Doc Difference</Link>,
        icon: React.createElement(FileSearchOutlined),
      },
      // {
      //   key: "k72",
      //   linkpath: "/rag",
      //   label: <Link to="/rag">RAG</Link>,
      //   icon: React.createElement(ReadOutlined),
      // },
      {
        key: "k73",
        linkpath: "/ppt-generator",
        label: <Link to="/ppt-generator">PPT Generator</Link>,
        icon: React.createElement(FilePptOutlined),
      },
    ],
  },
];

const AppLayout: React.FC = () => {
  // const [loading, setLoading] = React.useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [lightTheme, setlLightTheme] = useState(true);
  const location = useLocation();
  const [current, setCurrent] = useState("1");
  const [open, setOpen] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  // colorBgContainer

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // });

  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    let pathname = location.pathname;
    let filterItems = items.filter((e) => e.linkpath === pathname)[0];
    if (filterItems != null) {
      let e = {};
      e.key = filterItems.key;
      onClick(e);
    }
    setFormattedValue(formatItems(items1))
  }, [location]);

  const profileItems = [
    {
      // label: " Hi John ",
      key: "SubMenu",
      icon: <UserOutlined />,
      children: [
        {
          label: "Profile",
          key: "setting:1",
          icon: <UserOutlined />,
        },
        {
          label: <Link to="/">Logout</Link>,
          key: "setting:2",
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onProfileClick = (e) => {
    // setCurrent(e.key);
    if (e.key === "setting:1") {
      showDrawer();
    }
  };
  const history = useLocation();
  const formatedString = history.pathname.charAt(1).toUpperCase() + history.pathname.slice(2);
  const items1 = [
    {
      key: "PATIENT-NARRATIVES",
      value: "Safety Narratives Generation on SDTM",
    },
    {
      key: "SAP-INSIGHTS",
      value: "Generate titles for TLFs from SAP",
    },
    {
      key: "COGNITO",
      value: "General Query Chatbot",
    },
    {
      key: "SHERPA",
      value: " Datebot on SDTM/ADAM data",
    },
    {
      key: "PIL-GENERATOR",
      value: "Patient Information Leaflet Human Level Generation from SMPC",
    },
    {
      key: "CSR-GENERATOR",
      value: "",
    },
    {
      key: "CODE-GENERATOR",
      value: "",
    },
    {
      key: "DOC-DIFFERENCE",
      value: "Compare two protocol document versions",
    },
    {
      key: "PPT-GENERATOR",
      value: "PPT Generator for Site Initiation Study",
    },
  ]

    const formatItems = (items1)=>{
      const result  = items1.filter(item => item.key === formatedString.toUpperCase()).map(item=> item.value)
      return result.length > 0 ?  result[0] : "";
    }
    
  return (
    <Layout
      style={{ minHeight: "100vh" }}
      className={lightTheme === false ? "DarkMode" : ""}
    >
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          // background:"#1677ff"
        }}
      >
        <div className="demo-logo">
          <DeploymentUnitOutlined
            style={{ padding: "0px 8px 0px 6px", fontSize: "25px" }}
          />
          <DeploymentUnitOutlined
            style={{
              padding: "0px 8px 0px 6px",
              fontSize: "25px",
              position: "absolute",
              left: "25px",
              top: "15px",
              transform: "rotate(55deg)",
            }}
          />{" "}
          Synapse AI
        </div>
        <span style={{ fontWeight:"bold", fontSize:"14px", color:`${lightTheme === false  ? "#fff" : "#4B4B4C"}`}}>{formatedString.toUpperCase()} <span hidden={formattedValue === ""}>:</span><span  style={{ fontWeight:"normal", fontSize:"15px"}}> {formattedValue}</span></span>
        <div className="right-HeaderSection">
          <div style={{ padding: "0px 15px" }}>
            {/* <Switch
              onChange={(val) => {
                setlLightTheme(val);
              }}
              checkedChildren={<SunOutlined />}
              unCheckedChildren={<MoonFilled />}
              defaultChecked={lightTheme}
            /> */}
            {lightTheme && (
              <SunFilled
                onClick={() => setlLightTheme(!lightTheme)}
                style={{ fontSize: "21px", marginTop: "14px",  color:"#FC9601" }}
              />
            )}
            {!lightTheme && (
              <MoonFilled
                onClick={() => setlLightTheme(!lightTheme)}
                style={{ fontSize: "21px", marginTop: "14px", color:"yellow" }}
              />
            )}
          </div>
          {/* <div style={{ padding: "0px 5px" }}>
            <BellOutlined
              style={{ fontSize: "18px", marginTop: "14px", cursor: "pointer" }}
            />
          </div> */}
          <div style={{ padding: "0px 10px" }} className="Header-UserInfo">
            {/* <UserOutlined style={{ padding: "0px 5px" }} /> Hi Shailesh */}
            <Menu
              style={{ flex: "auto", justifyContent: "end" }}
              onClick={onProfileClick}
              selectedKeys={["1"]}
              mode="horizontal"
              items={profileItems}
            />
          </div>
          <Drawer
            width={640}
            placement="right"
            closable={false}
            onClose={onClose}
            open={open}
          >
            <p
              className="site-description-item-profile-p"
              style={{
                marginBottom: 24,
                fontSize: "20px",
                fontWeight: 400,
              }}
            >
              User Profile:
            </p>
            <p
              style={{ fontSize: "20px", fontWeight: 500 }}
              className="site-description-item-profile-p"
            >
              Personal
            </p>
            <Row>
              <Col span={10} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="Full Name"
                  content="John"
                />
              </Col>
              <Col span={12} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="Account"
                  content="John@XYZ.com"
                />
              </Col>
            </Row>
            <Row>
              <Col span={10} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="City"
                  content="Pune"
                />
              </Col>
              <Col span={12} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="Country"
                  content="India"
                />
              </Col>
            </Row>
            <Row>
              <Col span={10} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="Birthday"
                  content="February 2,1900"
                />
              </Col>
              <Col span={12} className="personalDetails">
                <DescriptionItem
                  className="personalDetails"
                  title="Website"
                  content="-"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="personalDetails">
                <DescriptionItem
                  title="Message"
                  content="Make things as simple as possible but no simpler."
                />
              </Col>
            </Row>
            <Divider />

            <p
              style={{ fontSize: "20px", fontWeight: 500 }}
              className="site-description-item-profile-p"
            >
              Contacts
            </p>
            <Row>
              <Col span={10}>
                <DescriptionItem title="Email" content="John@Saama.com" />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Phone Number"
                  content="+1 123 345 6078"
                />
              </Col>
            </Row>
          </Drawer>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            mode="inline"
            onClick={onClick}
            selectedKeys={[current]}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["k1"]}
            style={{ height: "100%", borderRight: 0, paddingTop: "13px" }}
            items={items}
          />
          {!collapsed && (
            <div
              className="Saama-logo"
              style={{
                marginTop: "-100px",
                marginLeft: "30px",
                marginBottom: "-50px",
              }}
            >
              <img src={lightTheme ? saamaLogoLight : saamaLogo} alt="logo" />
            </div>
          )}
        </Sider>
        <Layout
          style={{ padding: "15px 15px 15px", border: "1px dotted #cccc" }}
        >
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              // background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet></Outlet>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);
