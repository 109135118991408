import React, { useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Upload,
  Layout,
  Divider,
  Tabs,
  Empty,
  Spin,
  Select,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  ExportOutlined,
  // AreaChartOutlined,
  // PieChartOutlined,
  // BarChartOutlined,
} from "@ant-design/icons";
import "./PILGenerator.css";
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const contentStyle = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
  padding: "5px",
  overflow: "auto",
  height: "87vh",
  backgroundColor: "#fff",
  borderRadius:'5px'
  // minHeight: "90vh",
  // marginTop: "-25px",
};

const PILGenerator = () => {
  const [loading, setLoading] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [fileList, setFileList] = React.useState([]);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [Response, setResponse] = React.useState({});
  const [editableAnswers, setEditableAnswers] = React.useState({});
  const [selectedLinage, setSelectedLinage] = React.useState(
    "Possible side effects"
  );
  const [lineAgeAnswer, setLineAgeAnswer] = React.useState(null);

  const { Content } = Layout;

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleAnswerChange = (e, key) => {
    setEditableAnswers({
      ...editableAnswers,
      [key]: e.target.value,
    });
  };

  const handleSelectLinage = (value) => {
    setSelectedLinage(value);
  };

  const handleTabClick = (tab) => {};

  const handleGeneratePIL = async () => {
    setLoading(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    const file = fileList?.file;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_RAG_GEN}/generate_smpcpil`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          alert("Something went Wrong")
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setResponse(BackendResponse.data);
      } catch (error) {}
    }
    setTimeout(() => {
      setLoading(false);
      setContentVisible(true);
    }, 1500);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    const SelectedAnswer =
      Response?.LINEAGE &&
      Response?.LINEAGE.find((option) => option?.question === selectedLinage);
    setLineAgeAnswer(SelectedAnswer);
  }, [selectedLinage, lineAgeAnswer, Response]);

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={5}>
            <Card
              title="PIL Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="PIL-Generator-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload Data")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleGeneratePIL}
                    style={{ marginTop: "80px" }}
                    type="primary"
                    block
                  >
                    Generate PIL
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                PIL Input
              </p>
            </div>
          </Col>
        )}

        <Col span={isLeftNavVisible ? 23 : 19}>
          <Content style={contentStyle}>
            <Tabs
              size="small"
              onChange={handleTabClick}
              defaultActiveKey="1"
              type="card"
              tabBarExtraContent = { <>
                <Tooltip placement="bottom" title={"Export"}>
                    <Button
                      disabled={!contentVisible}
                      size="small"
                      type="primary"
                      icon={<ExportOutlined />}
                      style={{ marginLeft: "-80px", cursor: "pointer" }}
                    />
                  </Tooltip>
              </>}
              // animated= { inkBar: true, tabPane: true }
              // centered
            >
              <TabPane
                // icon={<AreaChartOutlined />}
                key="1"
                tab="PIL"
                style={{ padding: "10px" }}
              >
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <>
                    {Response?.PIL &&
                      Response.PIL.map((value, key) => (
                        <>
                          <Divider
                            orientation="left"
                            style={{ marginTop: "5px" }}
                          >
                            {value.question}
                          </Divider>
                          <TextArea
                            style={{
                              scrollbarWidth: "none",
                              padding: "10px",
                              marginBottom: "30px",
                            }}
                            className="textArea"
                            autoSize
                            value={editableAnswers[key] || value.answer}
                            onChange={(e) => handleAnswerChange(e, key)}
                          />
                        </>
                      ))}
                  </>
                ) : loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
              <TabPane
                // icon={<PieChartOutlined />}
                key="2"
                tab="HL"
                style={{ padding: "10px" }}
              >
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <>
                    {Response?.HL &&
                      Response.HL.map((value, key) => (
                        <>
                          <Divider
                            orientation="left"
                            style={{ marginTop: "5px" }}
                          >
                            {value.question}
                          </Divider>
                          <TextArea
                            style={{
                              scrollbarWidth: "none",
                              padding: "10px",
                              marginBottom: "30px",
                            }}
                            className="textArea"
                            autoSize
                            value={editableAnswers[key] || value.answer}
                            onChange={(e) => handleAnswerChange(e, key)}
                          />
                        </>
                      ))}
                  </>
                ) : loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
              <TabPane
                // icon={<BarChartOutlined />}
                key="3"
                tab="LINEAGE"
              >
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <>
                    <Row style={{ marginTop: "15px" }}>
                      <Col span={12}>
                        <label
                          style={{
                            fontWeight: "500",
                            marginRight: "10px",
                            fontSize: "14px",
                          }}
                        >
                          Select Question :
                        </label>
                        <Select
                          value={selectedLinage}
                          defaultValue="What X is and what it is used for"
                          style={{
                            width: 250,
                          }}
                          onChange={handleSelectLinage}
                        >
                          {Response?.LINEAGE &&
                            Response?.LINEAGE.map((value) => (
                              <Option value={value.question}>
                                {value.question}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Divider orientation="left" style={{ marginTop: "40px" }}>
                        {selectedLinage}
                      </Divider>
                      <Col span={12}>
                        <p
                          className="textArea"
                          style={{
                            color: "black",
                            textAlign: "left",
                            marginTop: "-5px",
                          }}
                        >
                          {lineAgeAnswer?.answer}
                        </p>
                      </Col>

                      <Col span={12}>
                        <p
                          className="textArea"
                          style={{
                            color: "black",
                            textAlign: "left",
                            marginTop: "-5px",
                          }}
                        >
                          {lineAgeAnswer?.lineage}
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
            </Tabs>
          </Content>
        </Col>
      </Row>
    </>
  );
};

export default PILGenerator;
