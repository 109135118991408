import React, { useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Upload,
  Empty,
  Spin,
  Tabs,
  Layout,
} from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

const contentStyle = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
  padding: "5px",
  overflow: "auto",
  height: "85vh",
  backgroundColor: "#fff",
};

const { TabPane } = Tabs;
const { Content } = Layout;

const CodeGeneration = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [fileList, setFileList] = React.useState([]);
  const [spin, setSpin] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  //   const [Response, setResponse] = React.useState([]);

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleTabClick = (tab) => {};

  const handleGenerateList = async () => {
    setSpin(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    //   try {
    //     const Response = await fetch(
    //       `${process.env.REACT_APP_CODE_GEN}/get-code/`,
    //       {
    //         method: "GET",
    //         headers: { "Content-Type": "application/json" },
    //       }
    //     );
    //     if (!Response.ok) {
    //       alert("Something went Wrong")
    //       throw new Error(`HTTP error! status: ${Response.status}`);
    //     }
    //     const BackendResponse = await Response.text();
    //     setResponse(BackendResponse);
    //   } catch (error) {}
    setTimeout(() => {
      setSpin(false);
      setContentVisible(true);
    }, 1500);
  };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={5}>
            <Card
              loading={loading}
              title="Code Generator Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="RAG-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload Data")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleGenerateList}
                    style={{ marginTop: "70px" }}
                    type="primary"
                    block
                  >
                    Generate Code
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                Code Generator Input
              </p>
            </div>
          </Col>
        )}

        <Col span={isLeftNavVisible ? 23 : 19}>
          <Content style={contentStyle}>
            <Tabs
              size="small"
              onChange={handleTabClick}
              defaultActiveKey="1"
              type="card"
            >
              <TabPane key="1" tab="PYTHON" style={{ padding: "10px" }}>
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <div
                    style={{
                      padding: "15px",
                      backgroundColor: "#0c0c46",
                      height: "70vh",
                      overflow: "auto",
                    }}
                  >
                    <pre
                      style={{
                        textAlign: "left",
                        color: "white",
                        marginTop: "-20px",
                      }}
                    >
                      {`
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/FavIcon.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Web site created using create-react-app"
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    <title>Synapse AI</title>
  </head>
  <body>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="root"></div>
  </body>
</html>`}
                    </pre>
                    <p
                      className="textArea"
                      style={{
                        color: "white",
                        textAlign: "left",
                        marginTop: "-5px",
                      }}
                    >
                      Subject ID: 053001 was Abacavir is used to treat HIV
                      (human immunodeficiency virus) infection Abacavir contains
                      the active ingredient abacavir. Abacavir belongs to a
                      group of anti-retroviral medicines called nucleoside
                      analogue reverse transcriptase inhibitors (NRTIs) Abacavir
                      does not completely cure HIV infection.
                    </p>
                  </div>
                ) : spin ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
              <TabPane key="2" tab="R" style={{ padding: "10px" }}>
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <div
                    style={{
                      padding: "15px",
                      backgroundColor: "#0c0c46",
                      height: "70vh",
                      overflow: "auto",
                    }}
                  >
                    <pre
                      style={{
                        textAlign: "left",
                        color: "white",
                        marginTop: "-20px",
                      }}
                    >
                      {`
    <meta
      name="description"
      content="Web site created using create-react-app"
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    <title>Synapse AI</title>
  </head>
  <body>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="root"></div>
  </body>
</html>`}
                    </pre>
                  </div>
                ) : spin ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
            </Tabs>
          </Content>
        </Col>
      </Row>
    </>
  );
};

export default CodeGeneration;
