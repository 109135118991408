import React, { useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Upload,
  Layout,
  Tabs,
  Tooltip,
  List,
  Empty,
  Space,
  Spin,
  Popconfirm,
  message,
} from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  // AreaChartOutlined,
  // PieChartOutlined,
  // BarChartOutlined,
  ExportOutlined,
  LikeOutlined,
  StarOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import "./SAPInsights.css";
const { TabPane } = Tabs;

const sampleData = 
{
    "questions": [
        {
            "question": "Safety analyses will be based on the Safety Analysis Set. No inferential statistics will be performed; only descriptive statistics will be provided.",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by Preferred Term"
        },
        {
            "question": "During the study participants will be assessed for response (to determine necessity of additional courses of CPX-351) by the investigator. Participants may receive up to four cycles of treatment between induction and consolidation cycles at the investigator’s discretion. The numbers and percentages of participants receiving 1 or 2 inductions, and 1, 2 or 3 consolidations will be provided. The number and percentage of participants with dose interruptions, if any, will also be summarized. Descriptive statistics will be provided for the cumulative dose received.",
            "answer": "Summary of Demographic Data\nSummary of Demographic Data by [Subgroup]\nSummary of Baseline Characteristics\nSummary of Baseline Characteristics by [Subgroup]\nSummary of Demographic Data and Baseline Characteristics\nSummary of Demographic Data and Baseline Characteristics by [Subgroup]\nOverall Summary of Treatment-Emergent Adverse Events\nOverall Summary of Treatment-Emergent Adverse Events by [Subgroup]\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term by [Subgroup]"
        },
        {
            "question": "Adverse events recorded in the case report form will be coded SOC and PT using the MedDRA, current or most recent version. The investigator will assess the relatedness of each AE to CPX-351. The severity of AEs will be recorded using the Common Terminology Criteria for Adverse Events (CTCAE).",
            "answer": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term by [Subgroup]\nTreatment-Emergent Adverse Events by Preferred Term\nTreatment-Emergent Adverse Events by Preferred Term by [Subgroup]\nTreatment-Emergent Adverse Events by System Organ Class\nTreatment-Emergent Adverse Events by System Organ Class by [Subgroup]"
        },
        {
            "question": "Adverse events are graded by the investigator as Grade 1 (mild), Grade 2 (moderate), Grade 3 (severe), Grade 4 (life threatening), or Grade 5 (fatal). The severity grade of events for which the severity was not recorded will be categorized as “missing” for summaries and listings, and will be considered the least severe for the purposes of sorting for data presentation.",
            "answer": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term and Maximum Severity\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term and Maximum Severity by [Subgroup]\nTreatment-Emergent Adverse Events by Preferred Term and Maximum Severity\nTreatment-Emergent Adverse Events by Preferred Term and Maximum Severity by [Subgroup]"
        },
        {
            "question": "Treatment-related AEs for CPX-351 are those for which investigators answer ‘Yes’ to the question ‘Is this event related to CPX-351 treatment?’ in the CRF. Events for which investigators do not record relationship to CPX-351 will be considered as related to CPX-351 for summary purpose. Listings will show treatment-relationship as missing.",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by Preferred Term\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class\n[AE Type] Treatment-Emergent Adverse Events by Preferred Term"
        },
        {
            "question": "Serious AEs are those for which investigators answers ‘Yes’ to the question ‘Serious?’ in the CRF.",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\n[Serious] Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\n[Serious] Treatment-Emergent Adverse Events by Preferred Term"
        },
        {
            "question": "A treatment-emergent adverse event (TEAE) is defined as any event with a start date on or after the first dose date and up to 30 days after the last dose of CPX-351, or any ongoing event that worsens in severity after the first dose date and up to 30 days after the last dose of CPX-351. Only TEAEs with the onset date through the end of the AE reporting period will be included in the summaries unless otherwise specified. For the purpose of calculating treatment emergence, incomplete onset dates will be imputed as detailed in Error! Reference source not found..",
            "answer": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term by [Subgroup]\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class and Preferred Term by [Subgroup]"
        },
        {
            "question": "DLTs are those for which investigators answers ‘Yes’ to the question ‘Is this event considered a Dose Limiting Toxicity (DLT)?’ in the CRF.",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nOverall Summary of Treatment-Emergent Adverse Events by [Subgroup]\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class and Preferred Term by [Subgroup]\n[AE Type] Treatment-Emergent Adverse Events by Preferred Term\n[AE Type] Treatment-Emergent Adverse Events by Preferred Term by [Subgroup]\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class\n[AE Type] Treatment-Emergent Adverse Events by System Organ Class by [Subgroup]"
        },
        {
            "question": "The following summaries of AEs will be provided for all participants in the Safety Analysis Set and will be presented by Cohort and Overall: Overall Summary of TEAEs, All TEAEs by System Organ Class and MedDRA Preferred Term (CTCAE Grade 1-5), All TEAEs by MedDRA Preferred Term (CTCAE Grade 1-5), Serious TEAEs by System Organ Class and MedDRA Preferred Term, Serious TEAEs by MedDRA Preferred Term, TEAEs Resulting in Death by System Organ Class and MedDRA Preferred Term, TEAEs by System Organ Class, MedDRA Preferred Term and Relationship, TEAEs by System Organ Class and MedDRA Preferred Term by Severity, TEAEs by System Organ Class and MedDRA Preferred Term (CTCAE Grade 3-5), TEAEs that Led to Interruption of study treatment (CPX-351), TEAEs that Led to Discontinuation of study treatment (CPX-351).",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by Preferred Term\nSerious Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\nSerious Treatment-Emergent Adverse Events by Preferred Term\nTreatment-Emergent Adverse Events Resulting in Death by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by System Organ Class\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term by Maximum Severity\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term (CTCAE Grade 3-5)\nTreatment-Emergent Adverse Events That Led to Interruption of Study Treatment (CPX-351)\nTreatment-Emergent Adverse Events That Led to Discontinuation of Study Treatment (CPX-351)"
        },
        {
            "question": "Treatment-emergent AEs will be summarized by PT, sorted in descending order of incidence. The other AE summaries will be provided by SOC and PT, and SOCs will be ordered alphabetically, with PTs within an SOC sorted in descending order of incidence. If a participant has more than 1 AE within a PT, the participant is counted only once at the maximum severity; if a participant has more than 1 AE within an SOC, the participant is counted once at the maximum severity when reporting results for that SOC.",
            "answer": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term\nTreatment-Emergent Adverse Events by Preferred Term\nTreatment-Emergent Adverse Events by System Organ Class"
        },
        {
            "question": "For public disclosure, the below summary tables will be generated for TEAEs for the Safety Analysis Set: Summary of Treatment-emergent Serious Adverse Events (for Public Disclosure), Summary of Treatment-emergent Non-serious Adverse Events Occurring in Greater Than 5% of Participants (for Public Disclosure).",
            "answer": "Overall Summary of Treatment-Emergent Adverse Events\nTreatment-Emergent Adverse Events by System Organ Class and Preferred Term\nSummary of Treatment-emergent Serious Adverse Events (for Public Disclosure)\nSummary of Treatment-emergent Non-serious Adverse Events Occurring in Greater Than 5% of Participants (for Public Disclosure)"
        },
        {
            "question": "For the following laboratory tests: Chemistry: Albumin, ALT (alanine aminotransferase), AST (Asparate aminotransferase), Calcium, Chloride, Creatinine, Glucose, Phosphorus, Potassium, Sodium, Total bilirubin, Direct bilirubin, and LDH, Hematology: Hemoglobin, Hematocrit, Platelets, Red Blood Cell Count, and White Blood Cell Count, and White Blood Cell Differential, a summary table (actual and change from baseline values) and a shift table of post-baseline results (in CTCAE grade) from baseline will be provided for each type of laboratory tests.",
            "answer": "Summary of Clinical Laboratory Data: Chemistry\nSummary of Clinical Laboratory Data: Chemistry by [Subgroup]\nSummary of Clinical Laboratory Data: Chemistry Absolute Values and Change from Baseline\nSummary of Clinical Laboratory Data: Chemistry Absolute Values and Change from Baseline by [Subgroup]\nSummary of Clinical Laboratory Data: Hematology\nSummary of Clinical Laboratory Data: Hematology by [Subgroup]\nSummary of Clinical Laboratory Data: Hematology Absolute Values and Change from Baseline\nSummary of Clinical Laboratory Data: Hematology Absolute Values and Change from Baseline by [Subgroup]\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: Chemistry\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: Chemistry by [Subgroup]\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: Hematology\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: Hematology by [Subgroup]"
        },
        {
            "question": "For each laboratory test, the shift table will include change in CTCAE grade from baseline for the worst post-baseline case. Lab values at unscheduled visits, if any, will also be considered.",
            "answer": "Summary of Clinical Laboratory Data: [Panel Name]\nSummary of Clinical Laboratory Data: [Panel Name] by [Subgroup]\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: [Panel Name]\nShift from Baseline in Clinical Laboratory Data CTCAE v[X.X] Grade: [Panel Name] by [Subgroup]"
        },
        {
            "question": "For systolic blood pressure, diastolic blood pressure, pulse rate, respiratory rate, and body temperature, a summary table of post-baseline results from baseline will be provided.",
            "answer": "Summary of Vital Signs Parameters\nSummary of Vital Signs Parameters by [Subgroup]"
        },
        {
            "question": "For ECG data (e.g., heart rate, RR interval, PR interval, QRS duration, QT interval, and QTcF interval) observed values and change from baseline data of 12 Lead ECG will be summarized for each cohort for the Safety Analysis Set.",
            "answer": "Summary of 12-Lead Electrocardiogram Parameters\nSummary of 12-Lead Electrocardiogram Parameters by [Subgroup]\nSummary of 12-Lead Electrocardiogram Parameters: Absolute Values and Change from Baseline\nSummary of 12-Lead Electrocardiogram Parameters: Absolute Values and Change from Baseline by [Subgroup]"
        },
        {
            "question": "All observed values and change from baseline of cardiac ejection fraction data by echocardiography or MUGA will be summarized for each cohort for the Safety Analysis Set. All echocardiography or MUGA data will also be listed.",
            "answer": "Summary of Clinical Laboratory Data: [Panel Name]\nSummary of Clinical Laboratory Data: [Panel Name] Absolute Values and Change from Baseline\nSummary of Clinical Laboratory Data: [Panel Name] Absolute Values and Change from Baseline by [Subgroup]"
        }
    ],
    "fileInfo": {
        "start_page": 16,
        "end_page": 20,
        "title_text": "10. SAFETY"
    },
    "subgroupInfo": {'PLACEHOLDER VALUES': {'treatment': 'CPX-351', 'subgroup': ['Normal', 'Moderate', 'Severe'], 'panel name': ['Chemistry', 'Hematology'], 'AE Type': ['Serious', 'Treatment-Related'], 'Custom Category Name or Standardized MedDRA Query': []}},
    "finalToc": [
        {
            "toc": "Summary of Screen Failures",
            "global_id": 0,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Study Disposition",
            "global_id": 1,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Major Protocol Deviations",
            "global_id": 2,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Analysis Sets",
            "global_id": 3,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Demographic Data",
            "global_id": 4,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Demographic Data by Cohort",
            "global_id": 5,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Baseline Characteristics",
            "global_id": 6,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Baseline Characteristics by Cohort",
            "global_id": 7,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Demographic Data and Baseline Characteristics",
            "global_id": 8,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Demographic Data and Baseline Characteristics by Cohort",
            "global_id": 9,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Prior Medications",
            "global_id": 10,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Concomitant Medications",
            "global_id": 11,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Overall Summary of Treatment-Emergent Adverse Events",
            "global_id": 12,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Overall Summary of Treatment-Emergent Adverse Events by Cohort",
            "global_id": 13,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term",
            "global_id": 14,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term by Cohort",
            "global_id": 15,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by Preferred Term",
            "global_id": 16,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by Preferred Term by Cohort",
            "global_id": 17,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term and Maximum Severity",
            "global_id": 18,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term and Maximum Severity by Cohort",
            "global_id": 19,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by Preferred Term and Maximum Severity",
            "global_id": 20,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by Preferred Term and Maximum Severity by Cohort",
            "global_id": 21,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Serious Treatment-Emergent Adverse Events by System Organ Class and Preferred Term",
            "global_id": 22,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Serious Treatment-Emergent Adverse Events by Preferred Term",
            "global_id": 23,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events Resulting in Death by System Organ Class and Preferred Term",
            "global_id": 24,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class",
            "global_id": 25,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term by Maximum Severity",
            "global_id": 26,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events by System Organ Class and Preferred Term (CTCAE Grade 3-5)",
            "global_id": 27,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events That Led to Interruption of Study Treatment (CPX-351)",
            "global_id": 28,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Treatment-Emergent Adverse Events That Led to Discontinuation of Study Treatment (CPX-351)",
            "global_id": 29,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Chemistry",
            "global_id": 30,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Chemistry by Cohort",
            "global_id": 31,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Chemistry Absolute Values and Change from Baseline",
            "global_id": 32,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Chemistry Absolute Values and Change from Baseline by Cohort",
            "global_id": 33,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Shift from Baseline in Clinical Laboratory Data CTCAE v5.0 Grade: Chemistry",
            "global_id": 34,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Shift from Baseline in Clinical Laboratory Data CTCAE v5.0 Grade: Chemistry by Cohort",
            "global_id": 35,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Hematology",
            "global_id": 36,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Hematology by Cohort",
            "global_id": 37,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Hematology Absolute Values and Change from Baseline",
            "global_id": 38,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Clinical Laboratory Data: Hematology Absolute Values and Change from Baseline by Cohort",
            "global_id": 39,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Shift from Baseline in Clinical Laboratory Data CTCAE v5.0 Grade: Hematology",
            "global_id": 40,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Shift from Baseline in Clinical Laboratory Data CTCAE v5.0 Grade: Hematology by Cohort",
            "global_id": 41,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Vital Signs Parameters",
            "global_id": 42,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Vital Signs Parameters by Cohort",
            "global_id": 43,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of 12-Lead Electrocardiogram Parameters",
            "global_id": 44,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of 12-Lead Electrocardiogram Parameters by Cohort",
            "global_id": 45,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of 12-Lead Electrocardiogram Parameters: Absolute Values and Change from Baseline",
            "global_id": 46,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of 12-Lead Electrocardiogram Parameters: Absolute Values and Change from Baseline by Cohort",
            "global_id": 47,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Echocardiography or MUGA",
            "global_id": 48,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        },
        {
            "toc": "Summary of Echocardiography or MUGA by Cohort",
            "global_id": 49,
            "is_like": false,
            "rating": 0,
            "feedback": "",
            "edit": false
        }
    ]
} ;

const contentStyle = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
  padding: "5px",
  height: "87vh",
  backgroundColor: "#fff",
  overflow: "auto",
  scrollbarWidth: "none",
  // minHeight: "90vh",
  // marginTop: "-25px",
};

const SAPInsights = () => {
  const [loading, setLoading] = React.useState(false);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [fileList, setFileList] = React.useState([]);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [Questions, setQuestions] = React.useState([]);
  const [userFileInfo, setUserFileInfo] = React.useState("");
  const [finalToc, setFinalToc] = React.useState("");
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [updateIndex, setUpdateIndex] = React.useState();
  // const [finalTocText, setFinalTocText] = React.useState(finalToc.toc);

  const { Content } = Layout;

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleSelectQuestion = (question) => {
    setSelectedQuestion(question);
  };

  const handleTabClick = (tab) => {};

  const handleSubmit = async () => {
    setLoading(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    const file = fileList?.file;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_SAP_URL}/handle_file`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          alert("Something went Wrong")
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setQuestions(BackendResponse.questions);
        // setUserFileInfo(BackendResponse.fileInfo);
        setUserFileInfo(BackendResponse.subgroupInfo);
        setFinalToc(BackendResponse.finalToc);
      } catch (error) {
        console.log("API Error")
        const BackendResponse:any = sampleData;
        setQuestions(BackendResponse.questions);
        setUserFileInfo(BackendResponse.subgroupInfo);
        setFinalToc(BackendResponse.finalToc);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setContentVisible(true);
    }, 1500);
  };

  const handleEditConfirm = () => {};
  const handleCancelEdit = () => {
    setUpdateIndex(null);
  };
  // const updateCall = (i) => {
  //   setUpdateIndex(i);
  // };

  const confirm = (e) => {
    console.log(e);
    setTimeout(() => {
    message.success("Successfully Deleted");
    }, 1000);
  };
  // const cancel = (e) => {
  //   console.log(e);
  //   message.error("Click on No");
  // };

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={5}>
            <Card
              title="SAP Input"
              style={{ minHeight: "80vh" }}
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="SAP-Insights-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload SAP Data")}
                    </Upload>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleSubmit}
                    style={{ marginTop: "80px" }}
                    type="primary"
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                SAP Input
              </p>
            </div>
          </Col>
        )}

        <Col span={isLeftNavVisible ? 23 : 19}>
          <Content style={contentStyle}>
            <Tabs
              tabBarExtraContent={
                <Tooltip placement="bottom" title={"Export"}>
                  <Button
                    disabled={!contentVisible}
                    size="small"
                    type="primary"
                    icon={<ExportOutlined />}
                    style={{ marginLeft: "-80px", cursor: "pointer" }}
                  />
                </Tooltip>
              }
              size="small"
              onChange={handleTabClick}
              // animated= { inkBar: true, tabPane: true }
              // centered
              defaultActiveKey="1"
              type="card"
            >
              <TabPane
                // icon={<AreaChartOutlined />}
                key="1"
                tab="Para to Probable TOC Map"
              >
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={13}>
                      <div
                        className="answer-content"
                        style={{ whiteSpace: "pre-line", marginTop: "-20px" }}
                      >
                        <div key={1}>
                          <h4 className="GeneratedTitle">
                            {"Text from SAP (Safety Section)"}
                          </h4>
                          <div
                            style={{
                              height: "70vh",
                              overflow: "auto",
                              scrollbarWidth: "none",
                            }}
                          >
                            {Questions.map((value, index) => (
                              <div
                                className={`question ${
                                  selectedQuestion === value ? "selected" : ""
                                }`}
                                key={index}
                              >
                                <p
                                  className="textArea"
                                  style={{
                                    color: "black",
                                    textAlign: "left",
                                    marginBottom: "5px",
                                    marginTop: "-1px",
                                  }}
                                  onClick={() => handleSelectQuestion(value)}
                                >
                                  {value.question}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={11}>
                      <div
                        className="answer-content"
                        style={{ whiteSpace: "pre-line", marginTop: "-20px" }}
                      >
                        <div key={1}>
                          <h4 className="GeneratedTitle">
                            {"Probale TOC from Global Template"}
                          </h4>
                          <p
                            className="textArea"
                            style={{
                              color: "black",
                              textAlign: "left",
                              marginTop: "-5px",
                            }}
                          >
                            {selectedQuestion && selectedQuestion.answer}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
              <TabPane
                // icon={<PieChartOutlined />}
                key="2"
                tab="Sub Group Info"
              >
                {contentVisible &&
                fileList &&
                fileList.fileList &&
                fileList?.fileList.length > 0 ? (
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={24}>
                      <div
                        className="answer-content"
                        style={{
                          whiteSpace: "pre-line",
                          marginTop: "-20px",
                          height: "80vh",
                          overflowY: "auto",
                          overflowX: "hidden",
                          scrollbarWidth: "none",
                        }}
                      >
                        <p
                          style={{ fontWeight: 500, marginBottom: "20px" }}
                          className="GeneratedTitle"
                        >
                          SAP Data
                        </p>
                        <div
                          style={{
                            padding: "20px",
                            // backgroundColor: "#0c0c46",
                            background:"#f5f5f5"
                          }}
                        >
                          {/* <pre style={{ textAlign: "left",}}>
                            {JSON.stringify(userFileInfo, null, 2)}
                          </pre> */}
                          <List>
                          {Object.entries(userFileInfo).map((item,key)=>{
                            let printTemplate = item[1]
                          return Object.entries(printTemplate).map(([key,value],index)=>{
                            return <List.Item>
                              <pre>
                               {key} : {Array.isArray(value)? value.join(","):value}
                              </pre>
                            </List.Item> 
                             })
                          })}
                          {/* <pre style={{textAlign:'left'}}>{userFileInfo}</pre> */}

                          </List>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : loading ? (
                  <Spin
                    style={{ marginTop: "150px" }}
                    tip="Loading..."
                    size="large"
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <Empty
                    style={{ marginTop: "150px" }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </TabPane>
              <TabPane
                // icon={<BarChartOutlined />}
                key="3"
                tab="Final TOC"
              >
                <List
                  itemLayout="horizontal"
                  size="large"
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 10,
                  }}
                  dataSource={finalToc}
                  renderItem={(item) => (
                    <List.Item
                      key={item.global_id}
                      actions={[
                        <IconText
                          icon={StarOutlined}
                          text="1"
                          key="list-vertical-star-o"
                        />,
                        <IconText
                          icon={LikeOutlined}
                          text="5 "
                          key="list-vertical-like-o"
                        />,
                        <IconText
                          icon={MessageOutlined}
                          text="2"
                          key="list-vertical-message"
                        />,
                        <span
                          style={{ color: "#1677ff", cursor:"pointer" }}
                          key="list-loadmore-edit"
                          // onClick={() => updateCall(item.global_id)}
                        >
                          Edit
                        </span>,

                        <Popconfirm
                          title="Delete the Item"
                          description="Are you sure to delete this item?"
                          onConfirm={confirm}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="left"
                        >
                          <span
                            style={{ color: "#1677ff", cursor:"pointer" }}
                            id={item.global_id}
                            key={item.global_id}
                          >
                            Delete
                          </span>
                        </Popconfirm>,
                      ]}
                    >
                      <div>
                        {updateIndex === item.global_id ? (
                          <form onSubmit={handleEditConfirm}>
                            <div className="input-group mb-2">
                              <input
                                className="form-control"
                                value={item.toc}
                                // onChange={(e) =>
                                //   setFinalTocText(e.target.value)
                                // }
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-success"
                                  type="button"
                                >
                                  Ok
                                </button>
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={handleCancelEdit}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <>
                            <span
                              style={{ textAlign: "left", fontWeight: 400 }}
                            >
                              {item.global_id} - {item.toc}
                            </span>
                          </>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </TabPane>
            </Tabs>
          </Content>
        </Col>
      </Row>
    </>
  );
};

export default SAPInsights;
