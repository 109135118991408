import React, { useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Checkbox,
  Upload,
  Select,
  Tooltip,
  Empty,
  Spin,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { jsPDF } from "jspdf";
import "./PatientNarratives.css";

const { TextArea } = Input;
const { Option } = Select;

const PatientNarrative = () => {
  const [loading, setLoading] = React.useState(true);
  const [uploadVisible, setUploadVisible] = React.useState(true);
  const [uploadVisible2, setUploadVisible2] = React.useState(true);
  const [spin, setSpin] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [fileList2, setFileList2] = React.useState([]);
  const [isLeftNavVisible, setIsLeftNavVisible] = React.useState(false);
  const [Questions, setQuestions] = React.useState([]);
  const [Answers, setAnswers] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState("053001");
  const [editableAnswers, setEditableAnswers] = React.useState({});

  const uploadButton = (title) => {
    return (
      <button
        type="button"
        style={{
          border: 0,
          background: "none",
        }}
      >
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const handleUploadChange = (file) => {
    setFileList(file);
  };

  const handleUploadChange2 = (file) => {
    setFileList2(file);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
    setEditableAnswers({});
  };

  const handleAnswerChange = (e, key) => {
    setEditableAnswers({
      ...editableAnswers,
      [key]: e.target.value,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  useEffect(() => {
    if (fileList && fileList.fileList && fileList?.fileList.length > 0) {
      setUploadVisible(false);
    } else {
      setUploadVisible(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (fileList2 && fileList2.fileList && fileList2?.fileList.length > 0) {
      setUploadVisible2(false);
    } else {
      setUploadVisible2(true);
    }
  }, [fileList2]);

  useEffect(() => {
    const SelectedAnswer = Questions.find(
      (option) => option?.question === selectedOption
    );
    setAnswers(SelectedAnswer);
  }, [selectedOption, Questions]);

  const handleGenerateNarrative = async () => {
    setSpin(true);
    setTimeout(() => {
      setIsLeftNavVisible(!isLeftNavVisible);
    }, 500);
    const file = fileList?.file;
    const file2 = fileList2?.file;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      // if (fileList2) {
        formData.append("input_template", file2);
      // }
      formData.append("gen_for_ae_only", true);
      try {
        const Response = await fetch(
          `${process.env.REACT_APP_API_URL}/handle_file`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!Response.ok) {
          alert("Something went Wrong")
          throw new Error(`HTTP error! status: ${Response.status}`);
        }
        const BackendResponse = await Response.json();
        setQuestions(BackendResponse.data.questions);
      } catch (error) {}
    }
    setTimeout(() => {
      setSpin(false);
      setContentVisible(true);
    }, 1500);
  };
  const DownlaodNarrative = async () => {
    const dataToDownload = { ...Answers.answer, ...editableAnswers };
    const doc = new jsPDF();
    const logoImage = new Image();
    logoImage.src = "./Saama.jpg";
    logoImage.onload = () => {
      doc.addImage(logoImage, "JPEG", 70, 10, 60, 20);
      let y = 50;
      const pageWidth = doc.internal.pageSize.width;
      const txtWidth = pageWidth - 20;
      Object.entries(dataToDownload).forEach(([key, value]) => {
        doc.setFontSize(16);
        let lines = doc.splitTextToSize(key, txtWidth);
        doc.text(lines, 10, y);
        y += lines.length * 8;
        doc.setFontSize(12);
        lines = doc.splitTextToSize(value, txtWidth);
        doc.text(lines, 10, y);
        y += lines.length * 8;
      });
      doc.save(`${selectedOption}_Answers.pdf`);
    };
  };

  // const uploadForm = async (file) => {
  //   if (file.file) {
  //     const formData = new FormData();
  //     formData.append("file", file.file);
  //     try {
  //       const Response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/add_ciom`,
  //         {
  //           method: "POST",
  //           body: formData,
  //         }
  //       );
  //       if (!Response.ok) {
  //         throw new Error(`HTTP error! status: ${Response.status}`);
  //       }
  //       const backendResponse = await Response.json();
  //       setAnswers({ ...Answers.answer, ...backendResponse });
  //     } catch (error) {}
  //   }
  // };

  return (
    <>
      <Row gutter={16}>
        {!isLeftNavVisible && (
          <Col span={7}>
            <Card
              style={{ minHeight: "80vh" }}
              loading={loading}
              title="Narrative Input"
              extra={
                <>
                  <LeftOutlined onClick={() => setIsLeftNavVisible(true)} />
                </>
              }
            >
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <div className="Patient-Narration-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      style={{ cursor: "pointer", width: "150px" }}
                      fileList={fileList && fileList.fileList}
                    >
                      {uploadVisible && uploadButton("Upload Data")}
                    </Upload>
                  </div>
                  <div className="Patient-Narration-Upload">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      beforeUpload={() => false}
                      onChange={handleUploadChange2}
                      style={{ cursor: "pointer" }}
                      fileList={fileList2 && fileList2.fileList}
                    >
                      {uploadVisible2 && uploadButton("Upload Template")}
                    </Upload>
                  </div>
                  <div className="Patient-Narration-Checkbox">
                    <div
                      style={{
                        marginTop: "10px",
                        marginRight: "64px",
                        marginBottom: "10px",
                      }}
                    >
                      <Checkbox>Patient with Serious AEs</Checkbox>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <Checkbox>Patient with AEs of Special Interest</Checkbox>
                    </div>
                  </div>
                  <Button
                    disabled={uploadVisible}
                    onClick={handleGenerateNarrative}
                    style={{ marginTop: "20px" }}
                    type="primary"
                    block
                  >
                    Generate Narrative
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {isLeftNavVisible && (
          <Col style={{ background: "white" }} span={1}>
            <div style={{ width: "fit-content", minHeight: "80vh" }}>
              <RightOutlined
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => setIsLeftNavVisible(false)}
              />
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  fontWeight: 500,
                  textAlign: "left",
                  marginTop: "150px",
                  marginLeft: "6px",
                }}
              >
                Narrative Input
              </p>
            </div>
          </Col>
        )}
        <Col span={isLeftNavVisible ? 23 : 17}>
          <Card
            loading={loading}
            style={{
              minHeight: "80vh",
              overflow: "auto",
              height: "85vh",
              scrollbarWidth: "none",
            }}
            extra={
              <>
                {!loading && (
                  <>
                    {contentVisible && Answers && (
                      <>
                        <label
                          style={{
                            fontWeight: "500",
                            marginRight: "10px",
                            fontSize: "13px",
                          }}
                        >
                          Select ID :
                        </label>
                        <Select
                          value={selectedOption}
                          style={{
                            width: 180,
                          }}
                          onChange={handleChange}
                        >
                          {Questions.map((value) => (
                            <Option value={value.question}>
                              {value.question}
                            </Option>
                          ))}
                        </Select>
                        <Tooltip placement="bottom" title={"Upload CIOMS Form"}>
                          {/* <Upload
                      onChange={uploadForm}
                      beforeUpload={() => false}
                      style={{ cursor: "pointer" }}
                      // fileList={fileList2}
                    > */}
                          <Button
                            disabled={!contentVisible}
                            size="small"
                            type="primary"
                            icon={<CloudUploadOutlined />}
                            style={{
                              marginRight: "5px",
                              marginLeft: "38px",
                              cursor: "pointer",
                            }}
                          />
                          {/* </Upload> */}
                        </Tooltip>
                        <Tooltip
                          placement="bottom"
                          title={"Download Narrative"}
                        >
                          <Button
                            onClick={DownlaodNarrative}
                            disabled={!contentVisible}
                            size="small"
                            type="primary"
                            icon={<DownloadOutlined />}
                            style={{ marginLeft: "28px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </>
            }
            title="Generated Patient Narrative"
          >
            <div
              className="answer-content"
              style={{ whiteSpace: "pre-line", marginTop: "-20px" }}
            >
              {contentVisible &&
              fileList &&
              fileList.fileList &&
              fileList?.fileList.length > 0 ? (
                <>
                  {Answers &&
                    Object.entries(Answers.answer).map(([key, value]) => (
                      <div key={key}>
                        <h3 className="GeneratedTitle">{key}</h3>
                        <TextArea
                          style={{ marginBottom: "20px" }}
                          autoSize
                          value={editableAnswers[key] || value}
                          onChange={(e) => handleAnswerChange(e, key)}
                        />
                      </div>
                    ))}
                </>
              ) : spin ? (
                <Spin
                  style={{ marginTop: "150px" }}
                  tip="Loading..."
                  size="large"
                >
                  <div className="content" />
                </Spin>
              ) : (
                <Empty
                  style={{ marginTop: "150px" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PatientNarrative;
